import React from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { withAuthentication } from "../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import Main from "../../components/main";
import ScreenLoad from "../../components/screenLoad";
import Button from "../../components/button";
import Input from "../../components/input";
import DropdownMenu from "../../components/dropdownMenu";
import Checkbox from "../../components/checkbox";
import ContentArea from "../../components/contentArea";
import SocialInput from "../../components/socialInput";
import SocialButton from "../../components/socialButton";
import TextArea from "../../components/textArea";
import Option from "../../components/option";
import GenreSelector from "../../components/genreSelector";
import SignOverlay from "../../components/signOverlay";
import AccountVerifyOverlay from "../../components/accountVerifyOverlay";
import SelectionBox from "../../components/selectionBox";
import ToggleSwitch from "../../components/toggleSwitch";
import DropdownInput from "../../components/dropdownInput";
import NavPanel from "../../components/navPanel";
import Consts from "../../config/consts";
import Common from "../../config/common";
import Provider from "../../config/provider";
import Api from "../../config/api";
import Util from "../../config/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes, faCheck, faGears, faBars } from "@fortawesome/free-solid-svg-icons";
import bgroovia_tile_icon_500 from "../../images/bgroovia_tile_icon_500.png";
import bminted_tile_icon_500 from "../../images/bminted_tile_icon_500.png";
import bgroovia_tile_icon_small from "../../images/bgroovia_tile_icon_small.png";
import bminted_tile_icon_small from "../../images/bminted_tile_icon_small.png";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED],
        redirectPage: "/",
    },
    class Profile extends React.Component {
        // export default class Home extends React.Component {
        constructor(props) {
            super(props);
            this.main_ref = React.createRef();
            const params = new URLSearchParams(props.location.search);
            this.state = {
                initialising: false,
                saving: false,
                requestData: {},
                requiresSigning: false,

                hasUpdatedGenres: false,
                hasUpdatedCurrency: false,
                hasUpdatedLanguage: false,
                hasUpdatedCommsMarketing: false,
                hasUpdatedCommsRegulatory: false,
                hasUpdatedCommsSecurity: false,
                hasUpdatedCommsGeneral: false,

                showAccountVerification: false,

                emailVerificationState: Common.objectPropertyExists(props, "auth.user.emailVerificationState", null),
                phoneVerificationState: Common.objectPropertyExists(props, "auth.user.phoneVerificationState", null),
                identityVerificationState: Common.objectPropertyExists(props, "auth.user.identityVerificationState", null),

                prefGenres: Common.objectPropertyExists(props, "auth.user.preferences.genres", []),
                prefCurrency: Common.objectPropertyExists(props, "auth.user.preferences.currency", "USD"),
                prefLanguage: Common.objectPropertyExists(props, "auth.user.preferences.language", "EN"),

                commsMarketing: Common.objectPropertyExists(props, "auth.user.communication.marketing", true) === true,
                commsRegulatory: Common.objectPropertyExists(props, "auth.user.communication.regulatory", true) === true,
                commsSecurity: Common.objectPropertyExists(props, "auth.user.communication.security", true) === true,
                commsGeneral: Common.objectPropertyExists(props, "auth.user.communication.general", true) === true,

                languages: [
                    {
                        id: "EN",
                        text: "English",
                    },
                    // {
                    //     id: "ES",
                    //     text: "Spanish",
                    // },
                    // {
                    //     id: "FR",
                    //     text: "French",
                    // },
                ],
                currencies: [
                    {
                        id: "USD",
                        text: "USD",
                    },
                    // {
                    //     id: "GBP",
                    //     text: "GBP",
                    // },
                ],
            };
        }

        componentDidMount() {}

        showAccountVerification = () => {
            this.setState({
                showAccountVerification: true,
            });
        };
        hideAccountVerificationOverlay = (refreshRequired) => {
            if (refreshRequired === true) {
                window.location.reload();
            } else {
                this.setState({
                    showAccountVerification: false,
                });
            }
        };

        hideSignOverlay = () => {
            this.setState({
                saving: false,
                requiresSigning: false,
            });
        };

        onShowEmailVerify = () => {
            if (this.main_ref) {
                this.main_ref.current.showEmailVerifyOverlay();
            }
        };

        onGenresChanged = (genres) => {
            this.setState({
                hasUpdatedGenres: true,
                prefGenres: genres,
            });
        };

        onCurrencyChange = (id) => {
            this.setState({
                hasUpdatedCurrency: true,
                prefCurrency: id,
            });
        };
        onLanguageChange = (id) => {
            this.setState({
                hasUpdatedLanguage: true,
                prefLanguage: id,
            });
        };
        commsMarketingChange = (id, switchedOn) => {
            this.setState({
                hasUpdatedCommsMarketing: true,
                commsMarketing: switchedOn,
            });
        };
        commsRegulatoryChange = (id, switchedOn) => {
            this.setState({
                hasUpdatedCommsRegulatory: true,
                commsRegulatory: switchedOn,
            });
        };
        commsSecurityChange = (id, switchedOn) => {
            this.setState({
                hasUpdatedCommsSecurity: true,
                commsSecurity: switchedOn,
            });
        };
        commsGeneralChange = (id, switchedOn) => {
            this.setState({
                hasUpdatedCommsGeneral: true,
                commsGeneral: switchedOn,
            });
        };

        onSaveAccount = () => {
            let request_data = {};
            if (this.state.hasUpdatedGenres) {
                request_data.preferredGenres = this.state.prefGenres;
            }
            if (this.state.hasUpdatedCurrency) {
                request_data.preferredCurrency = this.state.prefCurrency;
            }
            if (this.state.hasUpdatedLanguage) {
                request_data.preferredLanguage = this.state.prefLanguage;
            }
            if (this.state.hasUpdatedCommsGeneral) {
                request_data.generalCommunicationAllowed = this.state.commsGeneral;
            }
            if (this.state.hasUpdatedCommsMarketing) {
                request_data.marketingCommunicationAllowed = this.state.commsMarketing;
            }
            if (this.state.hasUpdatedCommsRegulatory) {
                request_data.regulatoryCommunicationAllowed = this.state.commsRegulatory;
            }
            if (this.state.hasUpdatedCommsSecurity) {
                request_data.securityCommunicationAllowed = this.state.commsSecurity;
            }

            if (Object.keys(request_data).length <= 0) {
                Util.notify.info("No changes found!");
            } else {
                this.setState({
                    saving: true,
                    requiresSigning: true,
                    requestData: request_data,
                });
                // this.setState(
                //     {
                //         saving: true,
                //     },
                //     () => {
                //         // Check if we require signing
                //         if (this.props.auth.user.requiresSigning === true) {
                //             this.setState({
                //                 requiresSigning: true,
                //             });
                //         } else {
                //             Api.market({
                //                 endpoint: "/user",
                //                 method: "PATCH",
                //                 data: request_data,
                //             })
                //                 .then(() => {
                //                     Util.notify.info("Saved Changes!");
                //                 })
                //                 .catch((e) => {
                //                     Util.notify.error("Failed to save changes, please retry or try again later.");
                //                 })
                //                 .finally(() => {
                //                     this.setState({
                //                         saving: false,
                //                     });
                //                 });
                //         }
                //     }
                // );
            }
        };

        onSaveWithSignature = (signature) => {
            if (!this.state.requestData || Object.keys(this.state.requestData).length <= 0) {
                Util.notify.info("Couldn't find anything to save! Please retry.");
            } else {
                this.setState(
                    {
                        requiresSigning: false,
                    },
                    () => {
                        Api.market({
                            endpoint: "/user",
                            method: "PATCH",
                            data: {
                                ...this.state.requestData,
                                signature,
                            },
                        })
                            .then(() => {
                                this.setState(
                                    {
                                        hasUpdatedGenres: false,
                                        hasUpdatedCurrency: false,
                                        hasUpdatedLanguage: false,
                                        hasUpdatedCommsMarketing: false,
                                        hasUpdatedCommsRegulatory: false,
                                        hasUpdatedCommsSecurity: false,
                                        hasUpdatedCommsGeneral: false,
                                    },
                                    () => {
                                        Util.notify.info("Saved Changes!");
                                    }
                                );
                            })
                            .catch((e) => {
                                Util.notify.error("Failed to save changes, please retry or try again later.");
                            })
                            .finally(() => {
                                this.setState({
                                    saving: false,
                                });
                            });
                    }
                );
            }
        };

        render() {
            return (
                <Main
                    ref={this.main_ref}
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                    noFooterMargin={true}
                >
                    <ContentArea extraTopPadding={true} centered={true} slim={true}>
                        {/* <ContentArea slim={true} header={"Wallets"} bottomRule={true}>
                                    <TextArea header={"Current Wallets"}></TextArea>
                                    <TextArea header={"Add New Wallet"}></TextArea>
                                </ContentArea> */}

                        <ContentArea header={"Account."} bottomRule={true}>
                            <TextArea header={"Verification."}>
                                <p>Verifying your account gives your account the verification badge which can help buyers trust that your account is legitimate.</p>
                                {this.state.identityVerificationState === "NOT_VERIFIED" ? (
                                    <p>
                                        Looks like your account hasn't been verified yet. Click the button below to start the verification.
                                        <br />
                                        <br />
                                        <Button displayMode={1} hoverMode={6} text={"Verify Your Account"} style={{ margin: 0 }} onClick={this.showAccountVerification} />
                                    </p>
                                ) : this.state.identityVerificationState === "PENDING" ? (
                                    <p>Your account is going through the verification process right now! We'll update you on the status when verification has completed</p>
                                ) : this.state.identityVerificationState === "VERIFIED" ? (
                                    <p>Your account has been verified. Congratulations!</p>
                                ) : this.state.identityVerificationState === "DENIED" ? (
                                    <p>Unfortunately at this time we've had to deny your verification attempt. Please raise a support ticket if you're unsure why.</p>
                                ) : this.state.identityVerificationState === "FAILED" ? (
                                    <p>
                                        Something went wrong during your verification attempt, please restart the process using the button below.
                                        <br />
                                        <Button displayMode={1} hoverMode={6} text={"Restart Verification"} style={{ margin: 0 }} onClick={this.showAccountVerification} />
                                    </p>
                                ) : this.state.identityVerificationState === "POSTPONED" ? (
                                    <p>We've needed to postpone your verification likely due to high demand. Your request is still with us and we'll get to it as soon as we can.</p>
                                ) : (
                                    <p>We're unsure on the current state of your account verification, please raise a support ticket and we'll take a look for you.</p>
                                )}
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Preferences."} bottomRule={true}>
                            <TextArea header={`Your Interests. (${this.state.prefGenres.length} Chosen)`}>
                                <p>
                                    What genres are you most interested in? We'll use this information to provide a tailored experience around the marketplace. For example, if you're really into
                                    traditional art we can show you more content that we believe is more in tune with this preference.
                                </p>
                                <GenreSelector selectedGenres={this.state.prefGenres} selectedGenresChanged={this.onGenresChanged} />
                            </TextArea>
                            <br />
                            <TextArea header={"Currency."}>
                                <p>
                                    What currency would you like to use throughout the website? Where possible we will utilise this option but in some locations we may need to display amounts in a
                                    different currency such as for crypto.
                                </p>
                                <DropdownInput options={this.state.currencies} selectedId={this.state.prefCurrency} onSelectionChange={this.onCurrencyChange} />
                            </TextArea>
                            <br />
                            <TextArea header={"Language."}>
                                <p>What language would you like text to be displayed in? For NFT media data we can only display in a different language if one has been supplied.</p>
                                <DropdownInput options={this.state.languages} selectedId={this.state.prefLanguage} onSelectionChange={this.onLanguageChange} />
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Communication."} bottomRule={true}>
                            <TextArea header={"Marketing."}>
                                <p>Occasionally we would like to reach out to select users with marketing opportunities. If you'd be interested in this toggle the below switch on.</p>
                                <ToggleSwitch id={"commsMarketing"} text={"Allow Marketing Communications?"} switch={this.state.commsMarketing} onSwitch={this.commsMarketingChange} />
                            </TextArea>
                            <br />
                            <TextArea header={"Regulatory."}>
                                <p>
                                    When it comes to blockchain regulations are everything. Occasionlly we'd like to reach out to discuss regulatory changes with you. You can opt out of these if you
                                    want but we advise that you stay in the know to avoid any surprises!
                                </p>
                                <ToggleSwitch id={"commsRegulatory"} text={"Allow Regulatory Communications?"} switch={this.state.commsRegulatory} onSwitch={this.commsRegulatoryChange} />
                            </TextArea>
                            <br />
                            <TextArea header={"Security."}>
                                <p>
                                    We will inform you of any security issues relating to the platform or your account unless you otherwise tell us. (This is likely one that you'll want to keep on
                                    too!)
                                </p>
                                <ToggleSwitch id={"commsSecurity"} text={"Allow Security Communications?"} switch={this.state.commsSecurity} onSwitch={this.commsSecurityChange} />
                            </TextArea>
                            <br />
                            <TextArea header={"General."}>
                                <p>General communication can cover any other topic that we may want to reach out to you about. This could include up coming changes or exciting announcements.</p>
                                <ToggleSwitch id={"commsGeneral"} text={"Allow General Communications?"} switch={this.state.commsGeneral} onSwitch={this.commsGeneralChange} />
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Email Address."} bottomRule={false}>
                            <TextArea header={"Verification."}>
                                <p>
                                    Verifying your Email Address gives us a better chance of recovering your account should it be captured, it also allows you to create and add media to your profile.
                                    We advise all users to verify your email address.
                                </p>
                                {this.state.emailVerificationState === "NOT_VERIFIED" ? (
                                    <p>
                                        Your email address is not yet verified, click the button below to start email verification.
                                        <br />
                                        <br />
                                        <Button displayMode={1} hoverMode={6} text={"Verify Your Email"} style={{ margin: 0 }} onClick={this.onShowEmailVerify} />
                                    </p>
                                ) : this.state.emailVerificationState === "VERIFIED" ? (
                                    <p>Your email address has been verified!</p>
                                ) : (
                                    <p>We're not sure on the current state of your email verification, please raise a support ticket and we'll take a look for you.</p>
                                )}
                            </TextArea>
                            <br />
                            {/* <TextArea header={"Change Email"}>
                                    <p>You can change your email address if you'd like, you will need to re-verify your new email address before it will replace your old email.</p>
                                </TextArea>
                                <br /> */}
                        </ContentArea>
                        <br />
                        <br />
                    </ContentArea>

                    <NavPanel
                        showScrollTop={true}
                        extraBottomMargin={
                            this.state.hasUpdatedCommsGeneral ||
                            this.state.hasUpdatedCommsMarketing ||
                            this.state.hasUpdatedCommsRegulatory ||
                            this.state.hasUpdatedCommsSecurity ||
                            this.state.hasUpdatedCurrency ||
                            this.state.hasUpdatedLanguage ||
                            this.state.hasUpdatedGenres
                        }
                    />

                    {(this.state.hasUpdatedCommsGeneral ||
                        this.state.hasUpdatedCommsMarketing ||
                        this.state.hasUpdatedCommsRegulatory ||
                        this.state.hasUpdatedCommsSecurity ||
                        this.state.hasUpdatedCurrency ||
                        this.state.hasUpdatedLanguage ||
                        this.state.hasUpdatedGenres) && (
                        <div className={styles.saveBar}>
                            <div className={styles.inner}>
                                <Button displayMode={9} hoverMode={6} large={true} disabled={this.state.saving === true} text={"Save Changes"} onClick={this.onSaveAccount} style={{ margin: 0 }} />
                            </div>
                        </div>
                    )}

                    {this.state.requiresSigning && (
                        <SignOverlay
                            onSigned={this.onSaveWithSignature}
                            addresses={this.props.auth.user.addresses}
                            onClose={this.hideSignOverlay}
                            currentChain={this.props.currentChain}
                            chains={this.props.chains}
                        />
                    )}
                    {this.state.showAccountVerification && <AccountVerifyOverlay user={this.props.auth.user} onClose={this.hideAccountVerificationOverlay} />}
                </Main>
            );
        }
    }
);
